import { Button, FormCheck, Modal } from "react-bootstrap";
import "./styles.scss";

const ShowAgreement = ({ show, setShow, setAgreementAccepted }) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      className="show-agreement-wrapper"
      show={show}
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Header className="modalTitle">
          <div className="show-agreement-title w-100 mt-2">Disclaimer, Confidentiality, and Data Use </div>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <div className="agreement-contents">
          <p>
            CHILD USA has created the Gold Standard child sex abuse (“CSA”)
            prevention tool app to allow organizations to assess their child CSA
            prevention policies (“App”) in furtherance of its charitable
            purpose. Your organization has opted to use the App as part of an
            ongoing assessment of its CSA prevention policies, which includes
            collecting questionnaires from specific individuals within your
            organization.
          </p>
          <p>
            Your organization has requested that you complete the questionnaire,
            which is available on the following page(s). Before you begin,
            please read the following information carefully and ensure you
            understand its contents.
          </p>
          <p className="italic">Disclaimer</p>
          <p>
            CHILD USA has worked to ensure the accuracy of the App, however,
            please be advised that the App relies on data obtained from multiple
            sources, including you, and CHILD USA cannot guarantee the accuracy
            of the results and recommendations generated by the App (“Results”).
            CHILD USA does not warrant the accuracy or effectiveness of the
            Results, and disclaims all liability for, any errors or other
            inaccuracies in, ineffectiveness of, or claims arising from, use of
            the App or the Results.{" "}
          </p>
          <p>
            You as a user acknowledge that (1) CHILD USA does not control any
            information you input into the App and the Results will reflect any
            inaccurate information you provide; (2) the App is only an
            informational tool and CHILD USA cannot guarantee that you or your
            organization will properly use or implement the Results; and (3) the
            Results may be inaccurate or ineffective in the event the App does
            not take into account certain relevant factors;.
          </p>
          <p className="bold italic">
            The App and the Results are provided “as is” and intended for
            informational purposes only. The App and the Results do not
            constitute a determination or certification by CHILD USA that the
            organization’s CSA prevention policies are adequate to prevent CSA
            or for any other purpose. Furthermore, while using the App may be a
            first step an organization may take toward bettering its
            CSA-prevention policies, CHILD USA does not claim or warrant that
            using the App is sufficient, in and of itself, to correct any
            deficiencies, identified or otherwise, in organization’s policies,
            procedures, or actual practice.
          </p>
          <p className="italic">Confidentiality</p>
          <p>
            You acknowledge that the App constitutes trade secrets and/or other
            proprietary and confidential information belonging to CHILD USA. You
            agree to hold the App in confidence for CHILD USA, and not to
            license, sell, rent, make available, or otherwise disclose the App
            or its contents, including methods or ideas used in the App, to
            anyone without the express permission of CHILD USA. The Results
            belong to you and may be shared with others.
          </p>
          <p className="italic">Limited Data Use</p>
          <p>
            You acknowledge that CHILD USA may use anonymized data collected
            through the App for research purposes. Anonymization is the process
            of removing personally identifiable information from data sets
            collected through the App, which results in data that cannot be
            associated with any one individual or organization. CHILD USA may
            use this data to further its charitable purpose for research
            purposes, which include, but are in no way limited to identifying
            which CSA prevention policies are being adopted or not, trends among
            youth-serving organizations (“YSOs”) in CSA prevention, and the
            quality of CSA prevention programs including categories defined by
            geography, context, or type of YSO.
          </p>
          <p className="bold">
            By clicking [Agree and Continue], you confirm that you have read and
            agree to this Disclaimer, Confidentiality and Data Use Policy and
            other relevant policies, i.e., Terms & Conditions, Privacy Policy,
            etc. on the CHILD USA.{" "}
          </p>
        </div>
        <div className="agree-buttons-wrapper w-100">
          <Button
            className="agreeBtn w-100"
            onClick={() => {
              setAgreementAccepted(true);
              setShow(false);
            }}
          >
            Agree and Continue
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowAgreement;

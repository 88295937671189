import React, { useEffect, useState } from "react";
import svg from '../../../assets/images/svg/index';
import { Formik } from 'formik';
import * as Yup from 'yup';
import NewPassConfirmation from './NewPassConfirmation.jsx';
import {resetPassword} from '../../../api/request';
import { Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { useWindowSize } from "../../../utils/useWindowSize.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './newpass.scss';

let mobileBreakpoint = 821;
const NewPassword = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [showNew1Password, setShowNew1Password] = useState(false);
    const [showNew2Password, setShowNew2Password] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const keyValue = queryParams?.get("key");
    const emailValue = decodeURIComponent(queryParams?.get("email").replace(' ', '+'));
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    const schema = Yup.object().shape({
        password: Yup.string().required('New Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character"),
        password2: Yup.string().required('Confirm New Password is required').matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
            "Password must contain at least 8 characters, one capital letter, one number, and one special character"),
    });

    return (
        <div className="UpdateNewPassword-wrapper">
            {
                getwidth >= mobileBreakpoint &&
                <div className="signup-img-wrapper">
                    <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
                    <div className="landing-page-txt" onClick={() => window.location.href = '/home'}>Go to Landing Page</div>
                </div>

            }
            <div className="signup-form">
                <div className="signup-form-body w-100">
                    <img src={svg?.LoginLogo} className="app-logo" alt="app logo NP" />
                    {
                        showSuccess === true ?
                            <NewPassConfirmation />
                            :
                            <Formik
                                validationSchema={schema}
                                initialValues={{ password: '', password2: '' }}
                                onSubmit={async (values) => {
                                    if (values?.password !== values?.password2) {
                                        toast.error("Password does not match");
                                        return;
                                    }
                                    setShowNew1Password(false)
                                    setShowNew2Password(false);
                                    let body = {
                                        "email": emailValue,
                                        "key": keyValue,
                                        "password1": values?.password,
                                        "password2": values.password2
                                    }
                                    try {
                                        let resp = await resetPassword(body);
                                        if (resp) {
                                            setShowSuccess(true);
                                            toast.success(resp?.message);
                                        }
                                    }
                                    catch (error) {
                                        toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                                    }

                                }}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue
                                    /* and other goodies */
                                }) => (
                                    <form
                                        className="login-form"
                                        onSubmit={handleSubmit}
                                        noValidate>
                                        <div className="Auth-form-content w-100">
                                            <div className="signin_heading">Reset Password</div>
                                            <div className="signin_subheading mt-1">Enter your new password</div>

                                            <Form.Group controlId="password">
                                                <div
                                                    className={`form-floating mb-3 mt-2 ${touched.password && errors.password
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        type={showNew1Password ? 'text' : 'password'}
                                                        name="password"
                                                        placeholder="New Password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        // isInvalid={touched.password && errors.password}
                                                    />
                                                    {!values.password && (
                                                        <label htmlFor="email" className="greyLabel">
                                                            New Password<span className="required-icon">*</span>
                                                        </label>
                                                    )}
                                                    <div className="input-group-append eyeicon-img">
                                                        <img src={showNew1Password ? svg?.PasswordShow : svg?.PasswordHide}
                                                            style={{ color: '#2563EB' }}
                                                            onClick={() => {
                                                                setShowNew1Password(!showNew1Password)
                                                            }}
                                                            alt="" />
                                                    </div>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.password}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="password2">
                                                <div
                                                    className={`form-floating mb-3 ${touched.password2 && errors.password2
                                                        ? 'has-validation-error'
                                                        : ''
                                                        }`}>
                                                    <Form.Control
                                                        type={showNew2Password ? 'text' : 'password'}
                                                        name="password2"
                                                        placeholder="Confirm NewPassword"
                                                        value={values.password2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        // isInvalid={touched.password2 && errors.password2}
                                                    />
                                                    {!values.password2 && (
                                                        <label htmlFor="email" className="greyLabel">
                                                            Confirm New Password<span className="required-icon">*</span>
                                                        </label>
                                                    )}
                                                    <div className="input-group-append eyeicon-img">
                                                        <img src={showNew2Password ? svg?.PasswordShow : svg?.PasswordHide}
                                                            style={{ color: '#2563EB' }}
                                                            onClick={() => {
                                                                setShowNew2Password(!showNew2Password)
                                                            }}
                                                            alt="" />
                                                    </div>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.password2}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>


                                            <div className="mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary signin_btn"
                                                    style={{ width: '100%' }}>
                                                    Change Password
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            </Formik>
                    }

                </div>
            </div>
        </div>
    )
}

export default NewPassword;
const EndPoints = {
  privacyPolicyUrl: "modules/privacy-policy/",
  termsConditions: "modules/terms-and-conditions/",
  contactUs: "api/v1/contact-us/",
  signup: "api/v1/signup/",
  businessProfile: "api/v1/business-categories/",
  userLogin: "api/v1/login/",
  logout: "api/v1/logout/",
  forgotPassword: "api/v1/forgot-password/",
  resetPass: "api/v1/reset-password/",
  changePass: "api/v1/change-password/",
  userProfile: "api/v1/profile/",
  updatePass: "api/v1/must-change-password/",
  survey: "api/v1/survey/",
  surveyListing: "api/v1/survey-history/",
  feedback: "api/v1/feedback/",
  subscriptions: "api/v1/subscriptions/",
  verifyAccessCode: "api/v1/verify-access-code/",
  confirmPayment: "modules/payments/confirm-payment/",
  PaymentMethod: "modules/payments/payment-method/"
}

export default EndPoints

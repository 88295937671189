import React, { useEffect, useState } from "react"
import Navbar from "../../CustomComponents/Navbar"
import BreadCrumbPath from "../../CustomComponents/BreadCrumbPath"
import BottomNavbar from "../../CustomComponents/BottomNavbar"
import { termsAndCondtions } from "../../api/request"
import Loader from "../../CustomComponents/Loader"
import "./styles.scss"
import { toast } from "react-toast"
import "react-toastify/dist/ReactToastify.css"

const TermsConditions = () => {
  const [getData, setData] = useState("")
  const [Loading, setLoading] = useState(false)
  useEffect(() => {
    fetchdata()
  }, [])
  const fetchdata = async () => {
    setLoading(true)
    try {
      let resp = await termsAndCondtions()
      setData(resp?.results?.[0]?.body)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toast.error(
        err?.response?.data?.message || "An error occured while fetching data"
      )
    }
  }

  const createMarkup = () => {
    return { __html: getData?.replace(/\r\n/g, "<br />") }
  }

  return (
    <>
      <Navbar hideHeaders="true" />
      <div className="terms-conditions-wrapper page-data">
        <BreadCrumbPath
          incomingPath1="terms-conditions"
          incomingPath1Text="Terms & Conditions"
        />
        <div className="terms-head">Terms and conditions</div>
        <div className="terms-divider"></div>
        <>
          {Loading === true ? (
            <Loader loadingMsg="Please wait while data is fetching.." />
          ) : (
            <div
              className="terms-body"
              dangerouslySetInnerHTML={createMarkup()}
            ></div>
          )}
        </>
      </div>
      <BottomNavbar />
    </>
  )
}

export default TermsConditions

import Logo from "./Logo.svg"
import Home from "./Home.svg"
import HomeSec from "./HomeSec.svg"
import Testing from "./Testing.svg"
import LoginBg from "./LoginBg.svg"
import LoginLogo from "./LoginLogo.svg"
import InfoIcon from "./InfoIcon.svg"
import PasswordShow from "./PasswordShow.svg"
import PasswordHide from "./PasswordHide.svg"
import Settings from "./Settings.svg"
import Logout from "./Logout.svg"
import PasswordSettings from "./PasswordSettings.svg"
import Account from "./Account.svg"
import Back from "./Back.svg"
import Survey from "./Survey.svg"
import Nexticon from "./Nexticon.svg"
import Previcon from "./Previcon.svg"
import UploadFile from "./UploadFile.svg"
import SelDate from "./SelDate.svg"
import AllDate from "./AllDate.svg"
import PaymentIcon from "./payment_icon.svg"
import SubscriptionIcon from "./subscription_icon.svg"
import VisaCardIcon from "./icon-visa-card.svg"
import MasterCardIcon from "./icon-master-card.svg"
import AmericanExpressIcon from "./icon-american-express.svg"
import DiscoverIcon from "./icon-discover.svg"
import DinersIcon from "./icon-diners.svg"
import CardIcon from "./icon-credit-card.svg"
import DeleteIcon from "./icon-delete.svg"
import JcbCardIcon from "./icon-jcb-card.svg"
import UnionPayIcon from "./icon-union-pay.svg"

export default {
  Logo: Logo,
  Home: Home,
  HomeSec: HomeSec,
  Testing: Testing,
  LoginBg: LoginBg,
  LoginLogo: LoginLogo,
  InfoIcon: InfoIcon,
  PasswordShow: PasswordShow,
  PasswordHide: PasswordHide,
  Settings: Settings,
  Logout: Logout,
  PasswordSettings: PasswordSettings,
  Account: Account,
  Back: Back,
  Survey: Survey,
  Nexticon: Nexticon,
  Previcon: Previcon,
  UploadFile: UploadFile,
  SelDate: SelDate,
  AllDate: AllDate,
  Payment: PaymentIcon,
  Subscription: SubscriptionIcon,
  VisaCardIcon,
  AmericanExpressIcon,
  DiscoverIcon,
  MasterCardIcon,
  DinersIcon,
  CardIcon,
  DeleteIcon,
  JcbCardIcon,
  UnionPayIcon
}

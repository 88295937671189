export const formatDate = (givenDateStr=new Date()) => {

    // Create a Date object from the given date string
    const givenDate = new Date(givenDateStr);

    // Define options for formatting
    const options = { day: 'numeric', month: 'long', year: 'numeric' };

    // Format the date using toLocaleDateString
    const formattedDate = givenDate.toLocaleDateString('en-US', options);
    return formattedDate

}
import React, { useEffect, useState } from "react";
import svg from '../../../assets/images/svg/index';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getUserProfile, updateUserProfile, fetchBusinessProfile } from '../../../api/request';
import Navbar from '../../../CustomComponents/Navbar';
import BottomNavbar from '../../../CustomComponents/BottomNavbar';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../../CustomComponents/Loader';
import { useWindowSize } from "../../../utils/useWindowSize.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const AccountSettings = () => {
    const { control, handleSubmit, formState: { errors } } = useForm();
    const phoneRegExp = /^\+?([0-9]{1,4})?[ .-]?\(?([0-9]{3})\)?[ .-]?([0-9]{3})[ .-]?([0-9]{4})$/;
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0)
    const [userData, setUserData] = useState({});
    const [showNew1Password, setShowNew1Password] = useState(false);
    const [showNew2Password, setShowNew2Password] = useState(false);
    const [orgTypeError, setorgTypeError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [setShowDisabled, setDisabled] = useState(true);
    const [showHide, setHide] = useState(false);
    const [orgTypeOptions, setOrgTypeOptions] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [orgTypeVal, setOrgTypeVal] = useState({});
    const [showPhoneError, setPhoneError] = useState(false);
    const [phoneErrorMsg, setphoneErrorMsg] = useState('');
    const [phoneVal, setPhoneVal] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleEditClick = () => {
        setDisabled(false);
        setHide(true);
    }
    const handleCancelClick = () => {
        window.location.href='/home';
        setDisabled(true);
        setHide(false);
    }
    const handlePhoneChange = (e) => {
        setPhoneError(false);
        setPhoneVal(e);
    }
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        setLoading(true);
        try {
            let resp = await getUserProfile();
            setUserData(resp[0]);
            setPhoneVal(resp[0]?.phone)
            fetchBusinessData(resp[0]);
        }
        catch (err) {
            toast.error(err?.response?.data?.message || 'An error occured while fetching user data');
            setLoading(false);
        }
    }
    const fetchBusinessData = async (uData) => {
        try {
            let resp = await fetchBusinessProfile();
            let arr = [];
            for (let i = 0; i < resp?.length; i++) {
                arr?.push({
                    label: resp[i]?.name,
                    value: resp[i]?.id
                })
            }
            setOrgTypeOptions(arr);
            let temp = arr?.filter((item) => (item?.value === uData?.business_category));
            setOrgTypeVal(temp[0]);

            setLoading(false);
        }
        catch (err) {
            toast.error(err?.response?.data?.message);
            setLoading(false);
        }
    }

    const schema = Yup.object().shape({
        businessName: Yup.string().required('Business Name is required'),
        contactName: Yup.string().required('Contact Name is required'),
    });

    const handleOrgTypeDropdown = (event) => {
        setOrgTypeVal(event);
    }

    return (
        <>
            <Navbar hideHeaders="true" />
            <div className="settings-wrapper UpdatePassword-wrapper">
                {
                    getwidth >= mobileBreakpoint &&
                    <div className="signup-img-wrapper">
                        <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
                        <div className="landing-page-txt" onClick={() => navigate(-1)}><img src={svg?.Back} className="back-icon" alt="back icon np" /></div>
                    </div>

                }
                <div className="signup-form">
                    <div className="signup-form-body">
                        {
                            Loading === true ?
                                <Loader loadingMsg="Please wait while data is fetching.." />
                                :
                                <Formik
                                    validationSchema={schema}
                                    initialValues={{ businessName: userData?.business_name || '', contactName: userData?.name || '', email: userData?.email || '', Address: userData?.address || '' }}
                                    onSubmit={async (values) => {
                                        if (orgTypeVal === null) {
                                            setorgTypeError(true);
                                            return;
                                        }
                                        else {
                                            setorgTypeError(false);
                                        }
                                        if (!phoneRegExp.test(phoneVal)) {
                                            setPhoneError(true);
                                            setphoneErrorMsg('Invalid phone number. Please use the format XXX-XXX-XXXX')
                                            return;
                                        }
                                        localStorage?.setItem('orgType', orgTypeVal?.value);
                                        let body = {
                                            "business_name": values?.businessName,
                                            "business_category": orgTypeVal?.value,
                                            "name": values?.contactName,
                                            "address": values?.Address,
                                            "phone": phoneVal
                                        }
                                        try {
                                            let resp = await updateUserProfile(body, userData?.id);
                                            if (resp) {
                                                toast.success('Account settings updated successfully');
                                                window?.location?.reload();
                                            }
                                        }
                                        catch (error) {
                                            toast.error(error?.response?.data?.message || 'An error occurred while updating account settings.')
                                        }

                                    }}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue
                                        /* and other goodies */
                                    }) => (
                                        <form
                                            className="login-form"
                                            onSubmit={handleSubmit}
                                            noValidate>
                                            <div className="Auth-form-content w-100">
                                                <div className="signin_heading">Account Settings</div>
                                                <Form.Group controlId="businessName">
                                                    <div
                                                        className={setShowDisabled === true ? `form-floating disabledFormFLoating mt-3 mb-3 ${touched.businessName && errors.businessName
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }` : `form-floating mt-3 mb-3 ${touched.businessName && errors.businessName
                                                                ? 'has-validation-error'
                                                                : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="text"
                                                            disabled={setShowDisabled}
                                                            name="businessName"
                                                            placeholder="Business Name"
                                                            value={values.businessName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched.businessName && errors.businessName}
                                                        />
                                                        {!values.businessName && (
                                                            <label htmlFor="businessName" className="greyLabel">
                                                                Business name<span className="required-icon">*</span>
                                                            </label>
                                                        )}
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.businessName}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>

                                                <Select
                                                    options={orgTypeOptions}
                                                    value={orgTypeVal}
                                                    placeholder={
                                                        <div className="select-placeholder-text">Organization type<span className="required-icon">*</span></div>
                                                    }
                                                    isDisabled={setShowDisabled}
                                                    onChange={handleOrgTypeDropdown}
                                                    components={{
                                                        Option: (props) => (
                                                            <div
                                                                {...props.innerProps}
                                                                className={'org-type-item'}
                                                            >
                                                                {props.children}
                                                            </div>
                                                        ),
                                                    }}
                                                    className={orgTypeError === true ? 'has-validation-error w-100 org-type-dropdown mt-2' : 'w-100 org-type-dropdown mt-2'}
                                                />
                                                {
                                                    orgTypeError === true &&
                                                    <Form.Control.Feedback type="invalid">
                                                        Organization type is required
                                                    </Form.Control.Feedback>

                                                }

                                                <Form.Group controlId="contactName">
                                                    <div
                                                        className={setShowDisabled === true ? `form-floating disabledFormFLoating mt-3 mb-3 ${touched.contactName && errors.contactName
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }` : `form-floating mt-3 mb-3 ${touched.contactName && errors.contactName
                                                                ? 'has-validation-error'
                                                                : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="text"
                                                            name="contactName"
                                                            disabled={setShowDisabled}
                                                            placeholder="Contact Person Name"
                                                            value={values.contactName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched.contactName && errors.contactName}
                                                        />
                                                        {!values.contactName && (
                                                            <label htmlFor="contactName" className="greyLabel">
                                                                Contact person name<span className="required-icon">*</span>
                                                            </label>
                                                        )}
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.contactName}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>

                                                <Form.Group controlId="email">
                                                    <div
                                                        className={setShowDisabled === true ? `form-floating disabledFormFLoating mt-3 mb-3 ${touched.email && errors.email
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }` : `form-floating mt-3 mb-3 ${touched.email && errors.email
                                                                ? 'has-validation-error'
                                                                : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="email"
                                                            disabled={true}
                                                            name="email"
                                                            placeholder="Business Email *"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched.email && errors.email}
                                                        />
                                                        {!values.email && (
                                                            <label htmlFor="email" className="greyLabel">
                                                                Business Email <span className="required-icon">*</span>
                                                            </label>
                                                        )}
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>

                                                <Form.Group controlId="phoneNumber" className="w-100">
                                                    <div
                                                        className={`form-floating phone-float mb-3 ${touched.phoneNumber && errors.phoneNumber
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <Controller
                                                            name="phoneNumber"
                                                            control={control}
                                                            defaultValue=""
                                                            rules={{
                                                                required: 'Phone number is required',
                                                                pattern: {
                                                                    value: phoneRegExp,
                                                                    message: 'Invalid phone number. Please use the format XXX-XXX-XXXX',
                                                                },
                                                            }}
                                                            render={({ field }) => (
                                                                <PhoneInput
                                                                    country={'us'}
                                                                    disabled={setShowDisabled}
                                                                    // value={values.phoneNumber}
                                                                    value={phoneVal}
                                                                    className="phone-input"
                                                                    onChange={(e) => handlePhoneChange(e)}
                                                                    onBlur={handleBlur}
                                                                />
                                                            )}
                                                        />
                                                        {
                                                            showPhoneError === true &&
                                                            <Form.Control.Feedback type="" className="text-danger invalid-terms-feedback">
                                                                {phoneErrorMsg}
                                                            </Form.Control.Feedback>
                                                        }
                                                    </div>
                                                </Form.Group>

                                                <Form.Group controlId="Address">
                                                    <div
                                                        className={`form-floating mt-3 mb-3 ${touched.Address && errors.Address
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="text"
                                                            name="Address"
                                                            disabled={setShowDisabled}
                                                            placeholder="Contact Person Name"
                                                            value={values.Address}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched.Address && errors.Address}
                                                        />
                                                        {!values.Address && (
                                                            <label htmlFor="Address" className="greyLabel">
                                                                Address
                                                            </label>
                                                        )}
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.Address}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>

                                                <div className="d-grid gap-2 mt-3">
                                                    {
                                                        showHide === true &&
                                                        <>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary signin_btn"
                                                                style={{ width: '100%' }}
                                                                disabled={setShowDisabled}
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                onClick={() => handleCancelClick()}
                                                                className="btn btn-primary cancel_btn"
                                                                style={{ width: '100%' }}>
                                                                Cancel
                                                            </button>
                                                        </>
                                                    }
                                                    {
                                                        showHide === false &&
                                                        <button
                                                            type="button"
                                                            onClick={() => handleEditClick()}
                                                            className="btn btn-primary signin_btn"
                                                            style={{ width: '100%' }}>
                                                            Edit
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                        }
                    </div>
                </div>
            </div>
            <BottomNavbar />
        </>
    )
}

export default AccountSettings;
import React, { useEffect, useState } from "react"
import svg from "../../../assets/images/svg/index"
import { Formik } from "formik"
import * as Yup from "yup"
import UpdatePassConfirmation from "./UpdatePassConfirmation.jsx"
import { Form } from "react-bootstrap"
import { updatePassword } from "../../../api/request"
import { useNavigate, useLocation, Navigate } from "react-router-dom"
import { useWindowSize } from "../../../utils/useWindowSize.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"

let mobileBreakpoint = 821
const UpdatePassword = () => {
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [showNew1Password, setShowNew1Password] = useState(false)
  const [showNew2Password, setShowNew2Password] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  const schema = Yup.object().shape({
    password: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        "Password must contain at least 8 characters, one capital letter, one number, and one special character"
      ),
    password2: Yup.string()
      .required("Confirm New Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        "Password must contain at least 8 characters, one capital letter, one number, and one special character"
      )
  })

  useEffect(()=>{
    console.log('location?.state::', location?.state)
    if(showSuccess){
      navigate('/subscriptions',{state: { fromSignUp: true }})
    }
  },[showSuccess])

  return (
    <div className="Update-Password-wrapper p-0">
      {getwidth >= mobileBreakpoint && (
        <div className="signup-img-wrapper">
          <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
          {/* <div className="landing-page-txt" onClick={() => window.location.href = '/home'}>Go to Landing Page</div> */}
        </div>
      )}
      <div className="signup-form">
        <div className="signup-form-body">
          <img src={svg?.LoginLogo} className="app-logo" alt="app logo NP" />
          {showSuccess === true ? (
            location?.state?.must_change_password ? (
              <Navigate to="/subscriptions" state={{ fromSignUp: true }} />
            ) : (
              <UpdatePassConfirmation />
            )
          ) : (
            <Formik
              validationSchema={schema}
              initialValues={{ password: "", password2: "" }}
              onSubmit={async values => {
                if (values?.password !== values?.password2) {
                  toast.error("Passwords does not match")
                  return
                }
                setShowNew1Password(false)
                setShowNew2Password(false)
                let body = {
                  password1: values?.password,
                  password2: values?.password2
                }
                try {
                  let resp = await updatePassword(body)
                  if (resp) {
                    toast.success(resp?.message)
                    setShowSuccess(true)
                  }
                } catch (error) {
                  toast.error(
                    error?.response?.data?.message ||
                      "An error occurred while updating your password."
                  )
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                /* and other goodies */
              }) => (
                <form className="login-form" onSubmit={handleSubmit} noValidate>
                  <div className="Auth-form-content w-100">
                    <div className="signin_heading">
                      Password Update Required
                    </div>
                    {/* <div className="signin_subheading mt-1">Enter your new password</div> */}

                    <Form.Group controlId="password">
                      <div
                        className={`form-floating mb-3 mt-2 ${
                          touched.password && errors.password
                            ? "has-validation-error"
                            : ""
                        }`}
                      >
                        <Form.Control
                          type={showNew1Password ? "text" : "password"}
                          name="password"
                          placeholder="New Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // isInvalid={touched.password && errors.password}
                        />
                        {!values.password && (
                          <label htmlFor="email" className="greyLabel">
                            New Password<span className="required-icon">*</span>
                          </label>
                        )}
                        <div className="input-group-append eyeicon-img">
                          <img
                            src={
                              showNew1Password
                                ? svg?.PasswordShow
                                : svg?.PasswordHide
                            }
                            style={{ color: "#2563EB" }}
                            onClick={() => {
                              setShowNew1Password(!showNew1Password)
                            }}
                            alt=""
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                    <Form.Group controlId="password2">
                      <div
                        className={`form-floating mb-3 ${
                          touched.password2 && errors.password2
                            ? "has-validation-error"
                            : ""
                        }`}
                      >
                        <Form.Control
                          type={showNew2Password ? "text" : "password"}
                          name="password2"
                          placeholder="Confirm NewPassword"
                          value={values.password2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // isInvalid={touched.password2 && errors.password2}
                        />
                        {!values.password2 && (
                          <label htmlFor="email" className="greyLabel">
                            Confirm New Password
                            <span className="required-icon">*</span>
                          </label>
                        )}
                        <div className="input-group-append eyeicon-img">
                          <img
                            src={
                              showNew2Password
                                ? svg?.PasswordShow
                                : svg?.PasswordHide
                            }
                            style={{ color: "#2563EB" }}
                            onClick={() => {
                              setShowNew2Password(!showNew2Password)
                            }}
                            alt=""
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.password2}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary signin_btn"
                        style={{ width: "100%" }}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  )
}

export default UpdatePassword

// Base Url for api requests
export const BASE_URL = window.location.origin + "/"

// successStatusCodes
export const successStatusCode = [200, 201, 202]

export const APP_JSON_HEADER = async (passToken = true, formDataParam) => {
  const token = localStorage.getItem("token") || ""
  let header = {}
  if (formDataParam === "true") {
    header["Content-Type"] = "multipart/form-data"
  } else {
    header["Content-Type"] = "application/json"
    header.Accept = "application/json"
  }

  if (passToken === true) {
    if (token) {
      header.Authorization = `Token ${token}`
    }
  }
  return header
}

export const STRIPE_KEY_STAGING =
  "pk_test_51OUY3vC3rfhMQPiMwh8p6wSeFWVTPMOENkRLAfjmOP3n7dlr2O9alMAltmw5vAAx6uYc4Zk9ZXRJolYZYdJDSb6q00IkaBlCVe"
export const STRIPE_KEY_LIVE =
  "pk_live_51OUY3vC3rfhMQPiMtfGk89IS9itn9tKUYblUx0C01wKTuCEAa97sZtrZRo0p6OKsZqJJ3Hn5OLb2lvo6coDowBUc00JJ31pGQK"

export const STRIPE_KEY =
  window.location.origin.includes("localhost") ||
  window.location.origin.includes("staging")
    ? STRIPE_KEY_STAGING
    : STRIPE_KEY_LIVE

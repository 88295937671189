import { Breadcrumb } from 'react-bootstrap';
import './styles.scss';

const BreadCrumbPath = (props) => {
    return (
        <Breadcrumb className='bread-crum-wrapper'>
            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
            <Breadcrumb.Item href={props?.incomingPath1}>
                {props?.incomingPath1Text}
            </Breadcrumb.Item>
        </Breadcrumb>
    );
}

export default BreadCrumbPath;
// BottomNavbar.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import svg from '../../assets/images/svg/index';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const BottomNavbar = () => {
    const navigate = useNavigate();
    return (
        <Navbar fixed="bottom" className='bottom-navbar'>
            <div className="navbar-logo">
                <img src={svg?.Logo} className="navbar-logo-img cursor-pointer" alt="logo Np"
                    onClick={() => navigate('/home')}
                />
            </div>
            <div className='bottom-nav-links'>
                <Nav className="nav-items">
                    <Nav.Link href="/terms-conditions">Terms and conditions</Nav.Link>
                    <Nav.Link href="/privacy-policy">Privacy policy</Nav.Link>
                </Nav>
                <div className='copyright-txt'>© 2023 CHILDUSA . All Rights Reserved.</div>
            </div>
        </Navbar>
    );
}

export default BottomNavbar;

import React, { useEffect, useState } from "react"
import svg from "../../../assets/images/svg/index"
import {
  getPaymentMethod,
  confirmPayment,
  removePaymentMethod
} from "../../../api/request"
import Navbar from "../../../CustomComponents/Navbar"
import BottomNavbar from "../../../CustomComponents/BottomNavbar"
import { useNavigate, useLocation } from "react-router-dom"
import Loader from "../../../CustomComponents/Loader"
import { useWindowSize } from "../../../utils/useWindowSize.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"
import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import UpdatePassConfirmation from "../UpdatePassword/UpdatePassConfirmation.jsx"
import StripeForm from "./StripeForm/StripForm.jsx"
import moment from "moment-timezone"

let mobileBreakpoint = 821
const Payments = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [Loading, setLoading] = useState(false)
  const [selectedCard, setSelectedCard] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { fromSubscriptions, fromSignUp, selectedPlan } = location?.state || {}
  const [savedCards, setSavedCards] = useState(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  useEffect(() => {
    const isAuthenticated = localStorage?.getItem("token")
    if (isAuthenticated) {
      getUserPaymentMethods()
    } else {
      navigate("/login")
    }
  }, [])

  const getUserPaymentMethods = async () => {
    setLoading(true)
    try {
      let resp = await getPaymentMethod()
      setSavedCards(resp?.data || [])
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while fetching saved cards"
      )
    } finally {
      setLoading(false)
    }
  }

  const handleCardSelection = cardInfo => () => {
    setSelectedCard(prev => (prev ? null : cardInfo))
  }

  const handlePayment = async (params = {}) => {
    setLoading(true)
    try {
      let resp = await confirmPayment({
        ...params,
        cents: selectedPlan?.cost * 100,
        plan_id: selectedPlan?.id
      })
      setSavedCards(resp?.data || [])
      toast?.success("Payment successful.")
      if (fromSignUp) {
        setShowSuccess(true)
        navigate("/home", { state: { selectedPlan } })
      } else if (fromSubscriptions) {
        navigate("/home", { state: { selectedPlan } })
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while processing payments"
      )
    } finally {
      setLoading(false)
    }
  }

  const handlePaymentBySavedCard = async () => {
    setLoading(true)
    try {
      let resp = await confirmPayment({
        payment_method_id: selectedCard?.id,
        cents: selectedPlan?.cost * 100,
        plan_id: selectedPlan?.id
      })
      if (fromSignUp) {
        setShowSuccess(true)
      } else if (fromSubscriptions) {
        navigate("/Dashboard", { state: { selectedPlan } })
        toast?.success("Payment successful.")
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while processing payments"
      )
    } finally {
      setLoading(false)
    }
  }

  const handleDeleteCard = cardInfo => async () => {
    setLoading(true)
    try {
      let resp = await removePaymentMethod({
        payment_method_id: cardInfo?.id
      })
      getUserPaymentMethods()
      if (selectedCard?.id === cardInfo?.id) {
        setSelectedCard(null)
      }
      toast?.success("Card removed successfully.")
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while removing the saved card payments"
      )
    } finally {
      setLoading(false)
    }
  }

  const getCardIcon = brand => {
    switch (brand) {
      case "visa":
      case "Visa":
        return svg?.VisaCardIcon
      case "MasterCard":
      case "mastercard":
        return svg?.MasterCardIcon
      case "American Express":
      case "amex":
        return svg?.AmericanExpressIcon
      case "Discover":
      case "discover":
        return svg?.DiscoverIcon
      case "diners":
      case "Diners Club":
        return svg?.DinersIcon
      case "jcb":
      case "Jcb":
        return svg?.JcbCardIcon
      case "unionpay":
      case "UnionPay":
        return svg?.UnionPayIcon
      default:
        return svg?.CardIcon
    }
  }

  return (
    <>
      <Navbar hideHeaders="true" />
      <div className="settings-wrapper UpdatePassword-wrapper">
        {getwidth >= mobileBreakpoint && (
          <div className="signup-img-wrapper">
            <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
            <div className="landing-page-txt" onClick={() => navigate(-1)}>
              <img src={svg?.Back} className="back-icon" alt="back icon np" />
            </div>
          </div>
        )}
        <div className="signup-form">
          <div className="signup-form-body">
            {Loading === true ? (
              <Loader loadingMsg="Please wait while data is fetching.." />
            ) : showSuccess ? (
              <UpdatePassConfirmation />
            ) : (
              <Box>
                <Typography
                  variant="header"
                  paragraph
                  className="signin_heading"
                  sx={{
                    fontFamily: "Perpetua Titling MT",
                    fontSize: "24px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    textAlign: "left",
                    color: "#111827"
                  }}
                >
                  {fromSubscriptions ? "PAYMENT METHOD" : "MY CARDS"}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {savedCards?.length > 0 ? (
                      savedCards?.map((cardInfo, index) => (
                        <Stack
                          key={`card-${index}`}
                          direction="row"
                          alignItems="center"
                          sx={{
                            borderRadius: "8px",
                            border: "1px solid",
                            borderColor:
                              selectedCard?.id === cardInfo?.id
                                ? "#C3981A"
                                : "#9CA3AF",
                            gap: "1rem",
                            padding: "8px",
                            cursor: "pointer"
                          }}
                          onClick={handleCardSelection(cardInfo)}
                          mb={2}
                        >
                          <img
                            src={getCardIcon(cardInfo?.card?.brand)}
                            alt="card"
                            style={{ width: "4rem", height: "4rem" }}
                          />
                          <Stack sx={{ flex: 1 }}>
                            <Typography
                              variant="body1"
                              sx={{
                                color: "#0B2E64",
                                fontSize: "14px",
                                textAlign: "left",
                                marginBottom: "0.5rem",
                                fontWeight: "600"
                              }}
                            >
                              XXXX-XXXX-XXXX-{cardInfo?.card?.last4}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                color: "#444",
                                fontSize: "12px",
                                textAlign: "left"
                              }}
                            >
                              valid Till: {cardInfo?.card?.exp_month}/
                              {cardInfo?.card?.exp_year}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                color: "#444",
                                fontSize: "12px",
                                textAlign: "left"
                              }}
                            >
                              Date Added:{" "}
                              {moment
                                .utc(cardInfo?.card?.created)
                                .tz(moment.tz.guess())
                                .format("MMM DD, YYYY")}
                            </Typography>
                          </Stack>
                          <img
                            src={svg?.DeleteIcon}
                            alt="delete"
                            onClick={handleDeleteCard(cardInfo)}
                            style={{ width: "2rem", height: "2rem" }}
                          />
                        </Stack>
                      ))
                    ) : (
                      <Typography variant="body1" sx={{ color: "#4B5563" }}>
                        No saved cards found.
                      </Typography>
                    )}
                  </Grid>
                  {fromSubscriptions && (
                    <>
                      {savedCards?.length > 0 && (
                        <Grid item xs={12}>
                          <button
                            type="button"
                            className={"btn btn-primary signin_btn w-100"}
                            onClick={handlePaymentBySavedCard}
                            disabled={!selectedCard?.id}
                          >
                            Confirm Payment
                          </button>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Divider
                          sx={{
                            "&::before, &::after": {
                              borderTop: "1px solid #4B5563"
                            },
                            ".MuiDivider-wrapper": {
                              margin: "0 2rem",
                              color: "#4B5563"
                            }
                          }}
                        >
                          Add a New Card
                        </Divider>
                      </Grid>
                      <Grid item container xs={12}>
                        <StripeForm
                          fromSubscriptions={fromSubscriptions}
                          handleNext={handlePayment}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            )}
          </div>
        </div>
      </div>
      <BottomNavbar />
    </>
  )
}

export default Payments

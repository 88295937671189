import React, { useEffect, useState } from "react";
import svg from '../../../assets/images/svg/index';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import PasswordSentConfirmation from './PasswordSentConfirmation.jsx';
import { forgotPass } from '../../../api/request';
import { useWindowSize } from "../../../utils/useWindowSize.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles.scss';

let mobileBreakpoint = 821;
const ForgotPassword = () => {
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);
    const [showOtp, setShowOtp] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width])

    const schema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('Email is required')
            .test(
                'has-supported-tld',
                'Invalid email',
                (value) => {
                    if (!value) {
                        return false; // Handle empty values as needed
                    }

                    const supportedTlds = ['.com', '.us', '.in', '.net', '.org', '.gov', '.edu', '.uk', '.au', '.co', '.io'];
                    const regex = new RegExp(`(${supportedTlds.join('|')})$`, 'i');

                    return regex.test(value);
                }
            ),
    });
    

    return (
        <>
            {
                showOtp === true ?
                    <PasswordSentConfirmation show={showOtp} />
                    :
                    <div className="ForgotPassword-wrapper">
                        {
                            getwidth >= mobileBreakpoint &&
                            <div className="signup-img-wrapper">
                                <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
                                <div className="landing-page-txt" onClick={() => window.location.href = '/home'}>Go to Landing Page</div>
                            </div>

                        }
                        <div className="signup-form">
                            <div className="signup-form-body">
                                <img src={svg?.LoginLogo} className="app-logo" alt="app logo NP" />
                                <Formik
                                    validationSchema={schema}
                                    initialValues={{ email: '' }}
                                    onSubmit={async (values) => {
                                        let body = {
                                            "email": values.email,
                                        }
                                        //if success
                                        try {
                                            let resp = await forgotPass(body);
                                            if (resp) {
                                                localStorage?.setItem('userEmail', values?.email);
                                                toast.success(resp?.message);
                                                // setShowOtp(true);
                                                // navigate('/verify-email');
                                            }
                                        }
                                        catch (error) {
                                            toast.error(error?.response?.data?.message || 'An error occurred while processing your request.')
                                        }

                                    }}>
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue
                                        /* and other goodies */
                                    }) => (
                                        <form
                                            className="login-form"
                                            onSubmit={handleSubmit}
                                            noValidate>
                                            <div className="Auth-form-content w-100">
                                                <div className="signin_heading">Reset your password</div>
                                                <div className="signin_subheading mt-1">Enter your Email and we’ll send you a link to reset your password</div>

                                                <Form.Group controlId="email">
                                                    <div
                                                        className={`form-floating mb-3 mt-2 ${touched.email && errors.email
                                                            ? 'has-validation-error'
                                                            : ''
                                                            }`}>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            placeholder="Email *"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="w-100"
                                                            isInvalid={touched.email && errors.email}
                                                        />
                                                        {!values.email && (
                                                            <label htmlFor="email" className="greyLabel">
                                                                Email <span className="required-icon">*</span>
                                                            </label>
                                                        )}
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>

                                                <div className="d-grid gap-2 mt-3">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary signin_btn"
                                                        style={{ width: '100%' }}>
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default ForgotPassword;
import "bootstrap/dist/css/bootstrap.min.css"
import "react-bootstrap-accordion/dist/index.css"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom"
import Home from "./Components/User/Home"
import TermsConditions from "./Components/TermsConditions"
import PrivacyPolicy from "./Components/PrivacyPolicy"
import Signup from "./Components/User/Signup"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./App.scss"
import Login from "./Components/User/Login"
import ForgotPassword from "./Components/User/ForgotPassword"
import UpdatePassword from "./Components/User/UpdatePassword"
import ChangePassword from "./Components/User/ChangePassword"
import AccountSettings from "./Components/User/AccountSettings"
import SpiderPoc from "./Components/SpiderPoc"
import TypeFormPoc from "./Components/TypeFormPoc"
import Dashboard from "./Components/User/Dashboard"
import SurveyDetailPage from './Components/User/Dashboard/SurveyDetailPage'
import NewPassword from "./Components/User/ForgotPassword/NewPassword"
import Subscriptions from "./Components/User/Subscriptions"
import Payments from "./Components/User/Payments"

function App() {
  const isAuthenticated = localStorage?.getItem("token")
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate to="home" />} />
          <Route exact path="home" element={<Home />} />

          <Route exact path="terms-conditions" element={<TermsConditions />} />
          <Route exact path="privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="sign-up" element={<Signup />} />
          <Route exact path="login" element={<Login />} />
          <Route exact path="forgot-password" element={<ForgotPassword />} />
          <Route exact path="reset-password" element={<NewPassword />} />
          <Route exact path="update-password" element={<UpdatePassword />} />

          <Route
            exact
            path="/change-password"
            element={
              // isAuthenticated ?
              <ChangePassword />
            }
          />

          <Route
            exact
            path="/settings"
            element={
              isAuthenticated ? <AccountSettings /> : <Navigate to="/login" />
            }
          />
          <Route exact path="poc" element={<SpiderPoc />} />
          <Route exact path="type-form" element={<TypeFormPoc />} />
          <Route
            exact
            path="/dashboard"
            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            exact
            path="/survey-details/:surveyID"
            element={isAuthenticated ? <SurveyDetailPage /> : <Navigate to="/login" />}
          />
          {/* <Route
            exact
            path="/subscriptions"
            element={
              isAuthenticated ? <Subscriptions /> : <Navigate to="/login" />
            }
          /> */}
          <Route
            exact
            path="/subscriptions"
            element={
              <Subscriptions />
            }
          />
          {/* <Route
            exact
            path="/payments"
            element={isAuthenticated ? <Payments /> : <Navigate to="/login" />}
          /> */}
          <Route
            exact
            path="/payments"
            element={<Payments />}
          />
        </Routes>
        <ToastContainer />
      </Router>
    </div>
  )
}

export default App

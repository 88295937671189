import React, { useEffect, useState } from "react";
import svg from '../../../assets/images/svg/index';
import { useNavigate } from "react-router-dom";

const PasswordSentConfirmation = () => {
    const navigate = useNavigate();
    return (
        <div className="UpdatePassConfirmation-Wrapper">
            <div className="info-img mt-3">
                {/* <img src={svg?.InfoIcon} className="info-icon" alt="info-icon" /> */}
            </div>
            <div className="acc-creation-txt">Forgot Password</div>
            <div className="acc-creation-sub-txt">Forgot password Reset link shared in email successfully.</div>
            {/* <button
                onClick={() => navigate('/login')}
                className="btn btn-primary signin_btn"
                style={{ width: '100%' }}>
                Login Now
            </button> */}
        </div>
    )
}

export default PasswordSentConfirmation;
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Navbar from "../../../CustomComponents/Navbar"
import BottomNavbar from "../../../CustomComponents/BottomNavbar"
import SurveyForm from "./SurveyForm.jsx"
import SurveyListing from "./SurveyListing.jsx"
import { fetchBusinessProfile, getUserProfile } from "../../../api/request"
import { useWindowSize } from "../../../utils/useWindowSize.js"
import SendFeedback from "./SendFeedback.jsx"
import svg from "../../../assets/images/svg/index"
import Select from "react-select"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"

let mobileBreakpoint = 820
const Dashboard = () => {
  const [orgTypeVal, setOrgTypeVal] = useState(null)
  const [orgTypeOptions, setOrgTypeOptions] = useState([])
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [useData, setUserData] = useState({})
  const navigate = useNavigate()

  useEffect(() => {
    fetchBusinessData()
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  useEffect(() => {
    fetchUserProfileInfo()
  }, [])

  const fetchUserProfileInfo = async () => {
    try {
      let resp = await getUserProfile()
      setUserData(resp[0])
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while fetching profile info"
      )
    }
  }

  const fetchBusinessData = async uData => {
    try {
      let resp = await fetchBusinessProfile()
      let arr = []
      for (let i = 0; i < resp?.length; i++) {
        arr?.push({
          label: resp[i]?.name,
          value: resp[i]?.id
        })
      }
      setOrgTypeOptions(arr)
      let temp = arr?.filter(
        item => item?.value === Number(localStorage.getItem("orgType"))
      )
      setOrgTypeVal(temp[0])
    } catch (err) {
      toast.error(err?.response?.data?.message)
    }
  }

  const handleOrgTypeDropdown = event => {
    setOrgTypeVal(event)
  }
  return (
    <>
      <Navbar />
      <div className="page-content">
        <div className="dashboard-wrapper">
          <div className="blue-bg-div"></div>
          <div className="dashboard-survey-wrapper">
            <div className="survey-content">
              <div className="survey-head">Take Our Assessment</div>
              <div className="survey-body">
                Using your organization's written child protection policies,
                complete the Gold Standard assessment to the best of your
                ability, answering "yes" or "no" to each question based on
                whether the requirement is fulfilled by your written policies.
                Results will provide an overall score and show where your
                policies can be strengthened.
              </div>
              {getwidth > mobileBreakpoint && (
                <img
                  src={svg?.Survey}
                  className="survey-img"
                  alt="survey-img"
                />
              )}
            </div>
            <Box
              className="survey-form"
              sx={
                useData?.is_allowed_for_survey
                  ? {}
                  : {
                      alignSelf: "flex-start",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "2.5rem",
                      padding: "100px 115px !important"
                    }
              }
            >
              {useData?.is_allowed_for_survey ? (
                <SurveyForm />
              ) : (
                <Box>
                  <Typography
                    variant="h1"
                    paragraph
                    sx={{
                      color: "#111827",
                      textAlign: "left",
                      fontFamily: "Perpetua Titling MT",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "28px",
                      marginBottom: "2rem"
                    }}
                  >
                    <img
                      src={svg?.InfoIcon}
                      className="info-icon"
                      alt="info icon"
                      style={{ marginRight: "0.5rem" }}
                    />
                    RENEW YOUR PLAN.
                  </Typography>
                  <Typography
                    variant="h1"
                    sx={{
                      color: "#6B7280",
                      textAlign: "left",
                      fontFamily: "Century Gothic",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "28px"
                    }}
                  >
                    You need to renew and choose one of our plans to take the
                    assessments again.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      background: "#C3981A",
                      height: "46px",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "white",
                      marginTop: "2.5rem"
                    }}
                    onClick={() => navigate("/subscriptions")}
                  >
                    View Plans
                  </Button>
                </Box>
              )}
            </Box>
          </div>
          <div className="survey-listing-wrapper">
            <SurveyListing />
          </div>
        </div>

        <div className="feedback-wrapper">
          <SendFeedback />
        </div>
      </div>
      <BottomNavbar />
    </>
  )
}

export default Dashboard

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  Elements
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useNavigate } from "react-router-dom"
import { useWindowSize } from "../../../../utils/useWindowSize"
import { useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { Grid, TextField } from "@mui/material"
import { styled } from "@mui/material/styles"
import { STRIPE_KEY } from "../../../../api/config"

const StyledInput = styled("div")({
  ".input-field": {
    border: "1px solid #9CA3AF",
    borderRadius: "8px",
    padding: "0 10px 10px",
    width: "100%",

    "& > label": {
      fontSize: "14px",
      textAlign: "left",
      color: "#444",
      width: "100%",
      marginBottom: "5px",
      fontFamily: "Roboto"
    }
  },

  ".error": {
    color: "#9e2146",
    width: "100%",
    textAlign: "left"
  },

  ".Mui-focused": {
    border: "0px"
  }
})

const stripePromise = loadStripe(STRIPE_KEY)

let mobileBreakpoint = 821
const StripeCardsSection = props => {
  const navigate = useNavigate()
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const stripe = useStripe()
  const elements = useElements()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [expDateErr, setExpDateErr] = useState(true)
  const [cardCvvErr, setCardCvvErr] = useState(true)
  const [cvvValue, setCvvValue] = useState("")
  const [cardNumErr, setCardNumErr] = useState(true)

  const cardExpiryRef = useRef(null)
  const cardNumberRef = useRef(null)
  const cardCvcRef = useRef(null)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()

  const onSubmit = async (data, event) => {
    setIsFormSubmitted(true)
    event.preventDefault()
    // Handle Stripe submission here
    if (!stripe || !elements) {
      // Stripe hasn't loaded yet
      return
    }

    const cardElement = elements.getElement(CardNumberElement)
    const { token, error } = await stripe.createToken(cardElement, {
      card: {
        cvc: data?.cardCvv,
        name: data?.cardName
      }
    })

    // Perform your validation logic here
    // if (!cardNumber || !cardCvv || !expDate) {
    //     toast.error("Please fill in all card details");
    //     return;
    // }

    // if (token) {
    // Call your server with the token for further processing
    if (token === undefined) {
      toast.error(error?.message || "Please provide a valid credit card number")
      return
    }
    cardElement?.clear()
    elements.getElement(CardExpiryElement)?.clear()
    elements.getElement(CardCvcElement)?.clear()
    reset?.({ cardName: "" })
    setIsFormSubmitted(false)
    const body = {
      stripe_token: token?.id,
      stripe_card_id: token?.card?.id
    }

    props?.handleNext?.(body)
  }

  return (
    <>
      <div className="payment-body-wrapper">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="payment-body-form-wrapper"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledInput>
                <div className="input-field">
                  <label>Card number</label>
                  <CardNumberElement
                    name="cardNumber"
                    id="card-number"
                    className="card-number-txt"
                    options={{
                      showIcon: false,
                      style: {
                        base: {
                          fontSize: "16px",
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          "::placeholder": {
                            color: "#999999",
                            fontFamily: "Roboto"
                          }
                        },
                        invalid: {
                          color: "#9e2146"
                        }
                      }
                    }}
                    onChange={event => {
                      cardNumberRef.current = event
                      setCardNumErr(event?.empty)
                    }}
                  />
                </div>
                {/* {errors.cardNumber && <p className="error text-danger-txt">{errors.expDate.message}</p>} */}
                {isFormSubmitted && cardNumErr && (
                  <div className="error">Card number is required</div>
                )}
              </StyledInput>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <StyledInput>
                  <div className="input-field">
                    <label>Valid Till</label>
                    <CardExpiryElement
                      name="expDate"
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            fontWeight: "400",
                            fontFamily: "Roboto",

                            "::placeholder": {
                              color: "#999999",
                              fontFamily: "Roboto"
                            }
                          },
                          invalid: {
                            color: "#9e2146"
                          }
                        }
                      }}
                      onChange={event => {
                        cardExpiryRef.current = event
                        setExpDateErr(event.empty)
                      }}
                    />
                  </div>
                  {isFormSubmitted && expDateErr && (
                    <div className="error">Expiration date is required</div>
                  )}
                </StyledInput>
              </Grid>
              <Grid item xs={6}>
                <StyledInput>
                  <div className="input-field">
                    <label>CVC</label>
                    <CardCvcElement
                      id="cvv"
                      options={{
                        style: {
                          base: {
                            fontSize: "16px",
                            fontWeight: "400",
                            fontFamily: "Roboto",
                            border: "1px solid #ced4da",
                            "::placeholder": {
                              color: "#999999",
                              fontFamily: "Roboto"
                            }
                          },
                          invalid: {
                            color: "#9e2146"
                          }
                        }
                      }}
                      onChange={event => {
                        cardCvcRef.current = event
                        setCardCvvErr(event.empty)
                        setCvvValue(event.complete ? event.value : "")
                      }}
                    />
                  </div>
                  {isFormSubmitted && cardCvvErr && (
                    <div className="error">CVC is required</div>
                  )}
                </StyledInput>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="cardName"
                rules={{
                  required: "Card holder name is required"
                }}
                render={({ field }) => (
                  <StyledInput>
                    <div className="input-field">
                      <label>Card holder's name</label>
                      <TextField
                        id="card-name"
                        variant="standard"
                        placeholder="Your name here"
                        fullWidth
                        margin="normal"
                        {...field}
                        InputProps={{
                          disableUnderline: true
                        }}
                        sx={{
                          "&.MuiFormControl-root": {
                            margin: 0,
                            border: 0,
                            fontFamily: "Roboto",

                            ".MuiInputBase-input": {
                              padding: 0,
                              "&::placeholder": {
                                color: "#999999",
                                opacity: 1,
                                fontFamily: "Roboto"
                              },
                              ":focus, .Mui-focused": {
                                border: 0,
                                borderColor: "#FF0000"
                              }
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                              border: 0,
                              ":focus-within": { border: 0 }
                            }
                          }
                        }}
                      />
                    </div>
                    {errors?.cardName && (
                      <div className="error" style={{ marginBottom: "0px" }}>
                        {errors?.cardName.message}
                      </div>
                    )}
                  </StyledInput>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <button
                type="button"
                className={"btn btn-primary signin_btn w-100"}
                onClick={handleSubmit(onSubmit)}
              >
                {props?.fromSubscriptions ? "Confirm Payment" : "Add Card"}
              </button>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  )
}

const StripeForm = props => {
  return (
    <Elements stripe={stripePromise}>
      <StripeCardsSection {...props} />
    </Elements>
  )
}

export default StripeForm

import React from "react";
import { Button, Modal } from 'react-bootstrap';
import SpiderGraph from './SpiderGraph.jsx';
import { formatDate } from '../../../utils/formatDate.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './surveydetail.scss';

const SurveyDetail = (props) => {
    const navigate = useNavigate();
    const outerPercentage = 100;
    const innerPercentage = 75;
    const handleClose = () => {
        props.handleDelClose();
    }
    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='survey-detail-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Body className="modalBody">
                <div className="survey-detail-body d-flex">
                    <div className="detail-parent">
                        <div className="detail-parent-head-wrapper">
                            <div className="detail-parent-title">{props?.surveyData?.survey_data?.name}</div>
                            <div className="detail-parent-percentage">{Math.floor(props?.surveyData?.survey_data?.percentage)}%</div>
                        </div>

                        <div className="detail-parent-subhead-wrapper">
                            <div className="detail-parent-date">{formatDate(props?.surveyData?.survey_data?.created)}</div>
                            <div className="detail-parent-data">{props?.surveyData?.survey_data?.correct_answer} / {props?.surveyData?.survey_data?.total_question}</div>
                        </div>

                        {/* <div className="detail-parent-sub-title">
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        </div> */}

                        <div className="parent-body-data">
                            <SpiderGraph spiderData={props?.surveyData?.survey_details_data} />
                        </div>
                    </div>

                    <div className="detail-child">
                        <div className="detail-child-title">Detail View</div>
                        <div className="detail-child-subtitle">{props?.surveyData?.survey_details_data?.feedback}</div>
                        <div className="detail-body d-flex">
                            {
                                props?.surveyData?.survey_details_data?.sections?.map((data, index) => (
                                    <div className="section-body-wrapper d-flex flex-col">
                                        <div className="section-name">{data?.section_name}</div>
                                        <div className="section-body">
                                            <div className='outer-bar-container'>
                                                <CircularProgressbar
                                                    value={outerPercentage}
                                                    strokeWidth={8} // Adjust the thickness of the outer progress bar
                                                    styles={{
                                                        trail: {
                                                            stroke: '#7DC5DD', // Customize the color of the outer progress bar's trail
                                                        },
                                                        path: {
                                                            stroke: '#7DC5DD', // Customize the color of the outer progress bar
                                                        },
                                                    }}
                                                />
                                                {/* Inner progress bar */}
                                                <div className='inner-bar-container'>
                                                    <CircularProgressbar
                                                        value={Math.floor(data?.percentage)
                                                        }
                                                        strokeWidth={10} // Adjust the thickness of the inner progress bar
                                                        text={`${Math.floor(data?.percentage)
                                                            }%`}
                                                        className='inner-bar'
                                                        styles={{
                                                            trail: {
                                                                stroke: 'transparent', // Set trail color to transparent
                                                            },
                                                            path: {
                                                                stroke: '#0B2E64', // Customize the color of the inner progress bar
                                                            },
                                                            text: {
                                                                fill: '#0B2E64', // Customize the text color
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-answers">{data?.correct_question}/{data?.total_question
                                        }</div>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SurveyDetail;
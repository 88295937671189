import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css'; // Import the styles for the CircularProgressbar
import './styles.scss';

const SpiderPoc = () => {
  const outerPercentage = 100;
  const innerPercentage = 75;

  return (
    <div className='spider-chart-poc'>
      {/* Outer progress bar */}
      <div className='outer-bar-container'>
        <CircularProgressbar
          value={outerPercentage}
          strokeWidth={5} // Adjust the thickness of the outer progress bar
          styles={{
            trail: {
              stroke: '#7DC5DD', // Customize the color of the outer progress bar's trail
            },
            path: {
              stroke: '#7DC5DD', // Customize the color of the outer progress bar
            },
          }}
        />
        {/* Inner progress bar */}
        <div className='inner-bar-container'>
          <CircularProgressbar
            value={innerPercentage}
            strokeWidth={10} // Adjust the thickness of the inner progress bar
            text={`${innerPercentage}%`}
            className='inner-bar'
            styles={{
              trail: {
                stroke: 'transparent', // Set trail color to transparent
              },
              path: {
                stroke: '#0B2E64', // Customize the color of the inner progress bar
              },
              text: {
                fill: '#0B2E64', // Customize the text color
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SpiderPoc;

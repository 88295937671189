import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Button, Modal } from "react-bootstrap"
import SpiderGraph from "./SpiderGraph.jsx"
import { formatDate } from "../../../utils/formatDate.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate, useLocation } from "react-router-dom"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import "./surveydetailpage.scss"
import Navbar from "../../../CustomComponents/Navbar"
import BottomNavbar from "../../../CustomComponents/BottomNavbar"
import Back from "../../../assets/images/svg/Back.svg"
import redCross from "../../../assets/images/svg/redCross.svg"
import { getSurveyDetail } from "../../../api/request"
import { useParams } from "react-router"
import Loader from "../../../CustomComponents/Loader"

const SurveyDetailPage = props => {

  const colorMap = {
    "Code of Conduct": "#FF6384",
    "Training": "#4BC0C0",
    "Whistleblower Protection": "#FFCD56",
    "Background Screening": "#C9CBCF",
    "Physical Environments": "#36A2EB",
    "Reporting": "#003F5C",
    "Investigations": "#58508D",
    "Victims' Rights": "#BC5090",
  }


  const navigate = useNavigate()
  const location = useLocation()
  const [surveyData, setSurveyData] = useState(null)
  const { surveyID } = useParams()
  const [loading, setLoading] = useState(true)
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(null)

  console.log("SurveyDetailPage:", location)
  //const surveyData = location?.state?.surveyData
  const outerPercentage = 100
  const innerPercentage = 75
  const handleClickBack = () => {
    navigate("/dashboard")
  }
  const getSurveyDetailData = async id => {
    try {
      const resp = await getSurveyDetail(id)
      setSurveyData(resp)
      setSelectedSectionIndex(0)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log("error::", err)
      toast.error(
        err?.response?.data?.detail || "Something went wrong. Please try again."
      )
    }
  }
  useEffect(() => {
    if (surveyID) {
      getSurveyDetailData(surveyID)
    }
  }, [])
  return (
    <>
      <Navbar />
      <Box
        component="main"
        className={"main"}
        sx={{
          flexGrow: 1,
          paddingBottom: `40px`,
          paddingRight: { xs: "0px", sm: "100px" },
          paddingLeft: { xs: "0px", sm: "100px" }
        }}
      >
        {loading === true ? (
          <Loader loadingMsg="Data is Loading... Please Wait" />
        ) : (
          <>
            <Grid
              container
              fullWidth
              direction="row"
              sx={{
                display: `flex`,
                minHeight: `100px`
              }}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Grid item sm={1} xs={3}>
                <Typography
                  className="arrowContainer"
                  alignItems={"center"}
                  justifyItems={"center"}
                  justifyContent="center"
                  style={{
                    display: `flex`,
                    cursor: "pointer"
                  }}
                  onClick={handleClickBack}
                >
                  <img
                    src={Back}
                    alt={"Back"}
                    style={{
                      height: "24px",
                      width: "24px"
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item sm={11} xs={9}>
                <Typography
                  className="surveyResultHeader"
                  alignItems={"center"}
                  justifyItems={"left"}
                  justifyContent="left"
                  style={{
                    display: `flex`
                  }}
                >
                  Results for your survey
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              fullWidth
              direction="row"
              sx={{
                display: `flex`,
                alignItems: `stretch`,
              }}
              alignItems={"start"}
              alignContent={"start"}
            >
              <Grid
                container
                direction="row"
                sx={{
                  display: `flex`
                }}
                xs={12}
                sm={5.8}
                className="surveyChartContainer"
              >
                <Grid item xs={6}>
                  <Typography
                    className="surveyName"
                    alignItems={"center"}
                    justifyItems={"left"}
                    justifyContent="left"
                    style={{
                      display: `flex`
                    }}
                  >
                    {surveyData?.survey_data?.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className="surveyPercentage"
                    alignItems={"center"}
                    justifyItems={"right"}
                    justifyContent="right"
                    style={{
                      display: `flex`
                    }}
                  >
                    {Math.floor(surveyData?.survey_data?.percentage)}%
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className="surveyDate"
                    alignItems={"center"}
                    justifyItems={"left"}
                    justifyContent="left"
                    style={{
                      display: `flex`
                    }}
                  >
                    {formatDate(surveyData?.survey_data?.created)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className="surveyRatio"
                    alignItems={"center"}
                    justifyItems={"right"}
                    justifyContent="right"
                    style={{
                      display: `flex`
                    }}
                  >
                    {surveyData?.survey_data?.correct_answer} /{" "}
                    {surveyData?.survey_data?.total_question}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className="surveyRatio"
                    alignItems={"center"}
                    justifyItems={"center"}
                    justifyContent="center"
                    style={{
                      display: `flex`
                    }}
                  >
                    <SpiderGraph spiderData={surveyData?.survey_details_data} />
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{
                  display: `flex`
                }}
                sm={0.2}
                xs={0}
              ></Grid>
              <Grid
                container
                direction="row"
                sx={{
                  display: `flex`,
                  marginTop: { xs: "5px", sm: "0px" }
                }}
                sm={6}
                xs={12}
                className="surveyChartContainer"
                justifyItems="center"
                alignContent={"start"}
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Typography
                    className="surveyName"
                    alignItems={"center"}
                    justifyItems={"left"}
                    justifyContent="left"
                    style={{
                      display: `flex`
                    }}
                  >
                    Detail View
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className="surveyFeedback"
                    fullWidth
                    alignItems={"center"}
                    justifyItems={"left"}
                    justifyContent="left"
                    style={{
                      display: `flex`
                    }}
                  >
                    {surveyData?.survey_details_data?.feedback}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  container
                  direction="row"
                  sx={{
                    display: `flex`
                  }}
                >
                  {surveyData?.survey_details_data?.sections?.map(
                    (data, index) => (
                      <Grid
                        item
                        direction="column"
                        sx={{
                          marginTop: `10px`,
                          display: `flex`,
                          alignItems: `center`,
                          marginBottom: `10px`
                        }}
                        sm={4}
                        xs={6}
                      >
                        <Grid item xs={12}>
                          <Typography className="sectionName" fullWidth>
                            {data?.section_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="outer-bar-container">
                            <CircularProgressbar
                              value={outerPercentage}
                              strokeWidth={8} // Adjust the thickness of the outer progress bar
                              styles={{
                                trail: {
                                  stroke: "#7DC5DD" // Customize the color of the outer progress bar's trail
                                },
                                path: {
                                  stroke: "#1D508F" // Customize the color of the outer progress bar
                                }
                              }}
                            />
                            {/* Inner progress bar */ console.log(data, "data")}
                            <div className="inner-bar-container">
                              <CircularProgressbar
                                value={Math.floor(data?.percentage)}
                                strokeWidth={10} // Adjust the thickness of the inner progress bar
                                text={`${Math.floor(data?.percentage)}%`}
                                className="inner-bar"
                                styles={{
                                  trail: {
                                    stroke: "transparent" // Set trail color to transparent
                                  },
                                  path: {
                                    stroke: colorMap[data?.section_name] || "#1D508F" // Customize the color of the inner progress bar
                                  },
                                  text: {
                                    fill: "#0B2E64" // Customize the text color
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      <Box
        className={"submain"}
        sx={{
          flexGrow: 1,
          paddingBottom: `40px`,
          paddingRight: { xs: "0px", sm: "100px" },
          paddingLeft: { xs: "0px", sm: "100px" }
        }}
      >
        {loading === true ? null : (
          <>
            <Grid
              container
              fullWidth
              direction="row"
              sx={{
                display: `flex`,
                minHeight: `100px`
              }}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Grid item xs={12}>
                <Typography
                  className="resultText"
                  alignItems={"center"}
                  justifyItems={"left"}
                  justifyContent="left"
                  style={{
                    display: `flex`,
                    color: `#000000`,
                    textTransform: "capitalize"
                  }}
                >
                  Results
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  justifyItems={"center"}
                  display={"flex"}
                  className={"underline"}
                  sx={{
                    width: { xs: "63px" }
                  }}
                ></Typography>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              direction="row"
              sx={{
                display: `flex`
              }}
            >
              {surveyData?.survey_details_data?.sections?.map((data, index) => (
                <Typography
                  className={
                    selectedSectionIndex === index
                      ? "resultSelectedSectionName"
                      : "resultSectionName"
                  }
                  onClick={() => {
                    if (selectedSectionIndex !== index) {
                      setSelectedSectionIndex(index)
                    }
                  }}
                >
                  {data?.section_name}
                </Typography>
              ))}
            </Grid>
            <Grid
              xs={12}
              container
              direction="row"
              sx={{
                display: `flex`,
                marginTop: `5px`
              }}
            >
              <Grid
                item
                xs={3}
                sx={{
                  display: `flex`
                }}
              >
                <Typography fullWidth className="resultAnalytics">
                  Total Question Section:
                  <span style={{ fontWeight: "700" }}>
                    {
                      surveyData?.survey_details_data?.sections[
                        selectedSectionIndex
                      ].total_question
                    }
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: `flex`
                }}
              >
                <Typography fullWidth className="resultAnalytics">
                  Questions attempted:
                  <span style={{ fontWeight: "700" }}>
                    {
                      surveyData?.survey_details_data?.sections[
                        selectedSectionIndex
                      ].total_question
                    }
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: `flex`
                }}
              >
                <Typography fullWidth className="resultAnalytics">
                  Policies present:
                  <span style={{ fontWeight: "700" }}>
                    {
                      surveyData?.survey_details_data?.sections[
                        selectedSectionIndex
                      ].correct_question
                    }
                  </span>
                </Typography>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: `flex`
                }}
              >
                <Typography fullWidth className="resultAnalytics">
                  Evaluation:
                  <span style={{ fontWeight: "700", color: "#82BD54" }}>
                    {
                      surveyData?.survey_details_data?.sections[
                        selectedSectionIndex
                      ].percentage
                    }
                    %
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              container
              direction="row"
              sx={{
                display: `flex`,
                marginBottom: `10px`
              }}
            >
              {surveyData?.survey_details_data?.sections[selectedSectionIndex]
                ?.wrong_questions?.length > 0 &&
                surveyData?.survey_details_data?.sections[
                  selectedSectionIndex
                ]?.wrong_questions?.map((w_question, index) => (
                  <>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: `flex`
                      }}
                    >
                      <Typography fullWidth className="resultWrongQuestion">
                        <span style={{ color: "#73C5DC" }}>Q.{index + 1} </span>
                        {w_question.question}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: `flex`
                      }}
                    >
                      {/* <Typography fullWidth className="resultWrongQuestion">
                                        <span style={{color:'#73C5DC'}}>Your Answer </span>
                                        <span style={{
                                            fontFamily: "Roboto",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "17px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color:"#666666"
                                            
                                        }}>{w_question?.choice_text}</span>
                                    </Typography> */}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: `flex`,
                        marginBottom: "10px"
                      }}
                    >
                      <Typography fullWidth className="missing">
                        <img
                          src={redCross}
                          alt={"missing"}
                          style={{
                            width: "24px",
                            height: "24px"
                          }}
                        />{" "}
                        Missing
                      </Typography>
                    </Grid>
                  </>
                ))}

              {surveyData?.survey_details_data?.sections[selectedSectionIndex]
                ?.wrong_questions?.length === 0 && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: `flex`,
                    paddingTop: "10px"
                  }}
                >
                  <Typography fullWidth className="resultWrongQuestion">
                    No Missing Policies, All good here.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Box>
      <BottomNavbar />
    </>
  )
}

export default SurveyDetailPage

import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { userLogout } from '../../../api/request';
import { toast } from 'react-toastify';
import svg from '../../../assets/images/svg/index';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import './styles.scss';

const Logout = (props) => {
    const navigate = useNavigate();
    const handleClose = () => {
        props.handleDelClose();
    }

    const handleLogout = async () => {
        try {
            let resp = await userLogout();
            if (resp?.message) {
                toast.success(resp?.message);
                localStorage.removeItem('token')
                localStorage.removeItem('isLoggedin');
                localStorage.removeItem('businessName');
                localStorage.removeItem('orgType');
                navigate('/login');
            }
        }
        catch (error) {
            toast.error(error?.response?.data?.message || 'An error occurred while logging out.')
        }
    }

    return (
        <Modal
            aria-labelledby="contained-modal-title-vcenter"
            className='delete-acc-wrapper'
            show={props.show}
            onHide={handleClose}
            centered>
            <Modal.Header>
                <Modal.Title className="modalTitle">
                    <div className='logout-header'>
                        <img src={svg?.InfoIcon} className='info-icon' alt="info icon" />
                    </div>
                    <div className='logout-title mt-2'>Log Out</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
                <div className="del-sub">
                    Are you sure you want to log out?
                </div>
                <div className='del-buttons-wrapper w-100'>
                    <Button
                        className="delBtn w-100" onClick={() => handleLogout()}>
                        Log Out
                    </Button>
                    <Button className="cancelBtn w-100" onClick={handleClose}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Logout;

import React, { useEffect, useState } from "react"
import svg from "../../../assets/images/svg/index"
import {
  getSubscriptions,
  verifyAccessCode,
  getUserProfile
} from "../../../api/request"
import Navbar from "../../../CustomComponents/Navbar"
import BottomNavbar from "../../../CustomComponents/BottomNavbar"
import { useNavigate, useLocation } from "react-router-dom"
import Loader from "../../../CustomComponents/Loader"
import { Form } from "react-bootstrap"
import { useWindowSize } from "../../../utils/useWindowSize.js"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { numToWord } from "../../../utils/helper.js"
import UpdatePassConfirmation from "../UpdatePassword/UpdatePassConfirmation.jsx"

let mobileBreakpoint = 821
const Subscriptions = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [height, width] = useWindowSize()
  const [getwidth, setWidth] = useState(0)
  const [subscriptionData, setSubscriptionData] = useState([])
  const [showHide, setHide] = useState(false)
  const [Loading, setLoading] = useState(false)
  const [accessCode, setAccessCode] = useState("")
  const navigate = useNavigate()
  const location = useLocation()
  const fromSignUp = location?.state?.fromSignUp
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [userData, setUserData] = useState(null)
  const activePlan = userData?.is_allowed_for_survey
    ? userData?.active_plan
    : ""
  const activeAccessCode = userData?.is_allowed_for_survey
    ? userData?.access_code
    : ""

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window?.innerWidth)
    }
  }, [width])

  useEffect(() => {
    const isAuthenticated = localStorage?.getItem("token")
    if (isAuthenticated) {
      getSubscriptionList()
      fetchUserProfileInfo()
    } else {
      navigate("/login")
    }
  }, [])

  // useEffect(() => {
  //   fetchUserProfileInfo()
  // }, [])

  const fetchUserProfileInfo = async () => {
    try {
      let resp = await getUserProfile()
      console.log("Profile>>>:", resp[0])
      localStorage?.setItem("orgType", resp[0]?.business_category)
      setUserData(resp[0])
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while fetching profile info"
      )
    }
  }

  const getSubscriptionList = async () => {
    setLoading(true)
    try {
      let resp = await getSubscriptions()
      setSubscriptionData(resp)
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "An error occurred while fetching subscription data"
      )
    } finally {
      setLoading(false)
    }
  }

  const selectPlan = plan => () => {
    setSelectedPlan(plan)
  }

  const handleAccessCodeChange = e => {
    setAccessCode(e?.target?.value)
  }

  const handleVerifyAccessCode = async () => {
    setLoading(true)
    try {
      await verifyAccessCode({ code: accessCode })

      toast.success("Access code verified successfully")
      if (fromSignUp) {
        setShowSuccess(true)
        navigate("/home")
      } else {
        fetchUserProfileInfo()
        navigate("/home")
      }
    } catch (error) {
      const errorMessage = (
        error?.response?.data?.message ||
        "An error occurred while verifying the access code"
      ).trim()
      const errors = {
        "code not match.": "Please provide an valid access code."
      }
      toast.error(errors[errorMessage] || errorMessage)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const handleSkip = () => {
    setShowSuccess(true)
    navigate("/home")
  }

  const handleChangePlan = () => {
    navigate("/payments", {
      state: { fromSubscriptions: true, fromSignUp, selectedPlan }
    })
  }

  return (
    <>
      <Navbar hideHeaders="true" />
      <div className="settings-wrapper UpdatePassword-wrapper">
        {getwidth >= mobileBreakpoint && (
          <div className="signup-img-wrapper">
            <img src={svg?.LoginBg} className="signup-bg" alt="signup-Np" />
            <div
              className="landing-page-txt"
              style={{
                marginTop: "30px"
              }}
              onClick={() => navigate("/home")}
            >
              <img src={svg?.Back} className="back-icon" alt="back icon np" />
            </div>
          </div>
        )}
        <div className="signup-form">
          <div className="signup-form-body">
            {Loading === true ? (
              <Loader loadingMsg="Please wait while data is fetching.." />
            ) : showSuccess ? (
              <UpdatePassConfirmation />
            ) : (
              <Box>
                <Typography
                  variant="header"
                  paragraph
                  className="signin_heading"
                  sx={{
                    fontFamily: "Perpetua Titling MT",
                    fontSize: "24px",
                    fontWeight: "400",
                    lineHeight: "28px",
                    textAlign: "left",
                    color: "#111827"
                  }}
                >
                  {fromSignUp ? "PLANS WE OFFER" : "MY PLANS"}
                </Typography>
                <Stack justifyContent="space-between" spacing={3}>
                  {Boolean(activePlan) ? (
                    <Stack
                      key={`${activePlan?.id}-active-plan`}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                      sx={{
                        padding: "25px",
                        borderRadius: "8px",
                        border: "1px solid #C3981A",
                        position: "relative",
                        marginTop: "1.25rem !important"
                      }}
                    >
                      <Typography
                        variant="body"
                        component="div"
                        sx={{
                          padding: "7px 40px",
                          borderRadius: "8px 10px 10px 0px",
                          background: "#C3981A",
                          position: "absolute",
                          top: "-14px",
                          left: 0,
                          color: "#FFF",
                          fontFamily: "Roboto",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "700",
                          lineHeight: "20px"
                        }}
                      >
                        Active Plan
                      </Typography>
                      <div>
                        <Typography
                          variant="body"
                          paragraph
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "1.25rem",
                            color: "#0B2E64",
                            fontWeight: "500",
                            textAlign: "left"
                          }}
                        >
                          {activePlan?.name}
                        </Typography>
                        <Typography
                          variant="body"
                          paragraph
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "0.875rem",
                            color: "#444",
                            fontWeight: "400",
                            margin: 0,
                            textAlign: "left"
                          }}
                        >
                          {Boolean(activePlan?.description) ? (
                            activePlan?.description
                          ) : (
                            <>
                              You will get access to the assessment for&nbsp;
                              <span
                                style={{ color: "#0B2E64", fontWeight: "700" }}
                              >
                                {numToWord(activePlan?.limit)}
                              </span>
                              &nbsp;
                              {activePlan?.limit > 1 ? "times" : "time"}.
                            </>
                          )}
                        </Typography>
                      </div>
                      <Typography
                        variant="header"
                        component="div"
                        sx={{
                          fontFamily: "Roboto",
                          fontSize: "3rem",
                          color: "#0B2E64",
                          fontWeight: "300"
                        }}
                      >
                        ${activePlan?.cost}
                      </Typography>
                    </Stack>
                  ) : (
                    activeAccessCode && (
                      <div>
                        <Typography
                          variant="subtitle1"
                          paragraph
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "24px",
                            fontWeight: "600",
                            lineHeight: "28px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#00000",
                            margin: 0
                          }}
                        >
                          You are using&nbsp;
                          <span style={{ color: "#0B2E64" }}>Access Code</span>
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          paragraph
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: "400",
                            lineHeight: "28px",
                            letterSpacing: "0em",
                            textAlign: "left",
                            color: "#444",
                            margin: 0
                          }}
                        >
                          {activeAccessCode}
                        </Typography>
                      </div>
                    )
                  )}
                  {(Boolean(activePlan) || activeAccessCode) && (
                    <Divider
                      sx={{
                        "&::before, &::after": {
                          borderTop: "1px solid #4B5563"
                        },
                        ".MuiDivider-wrapper": { margin: "0 2rem" }
                      }}
                    >
                      Our Plans
                    </Divider>
                  )}
                  {subscriptionData
                    ?.slice(0, 2)
                    ?.filter(item => activePlan?.id !== item.id)
                    ?.map((item, i) => (
                      <Stack
                        key={`${item?.id}-${i}`}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{
                          padding: "25px",
                          borderRadius: "8px",
                          border: "1px solid",
                          borderColor:
                            selectedPlan?.id === item?.id
                              ? "#C3981A"
                              : "#9CA3AF",
                          position: "relative",
                          cursor: "pointer"
                        }}
                        onClick={selectPlan(item)}
                      >
                        <div>
                          <Typography
                            variant="body"
                            paragraph
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "1.25rem",
                              color: "#0B2E64",
                              fontWeight: "500",
                              textAlign: "left"
                            }}
                          >
                            {item?.name}
                          </Typography>
                          <Typography
                            variant="body"
                            paragraph
                            sx={{
                              fontFamily: "Roboto",
                              fontSize: "0.875rem",
                              color: "#444",
                              fontWeight: "400",
                              margin: 0,
                              textAlign: "left"
                            }}
                          >
                            {Boolean(item?.description) ? (
                              item?.description
                            ) : (
                              <>
                                You will get access to the assessment for&nbsp;
                                <span
                                  style={{
                                    color: "#0B2E64",
                                    fontWeight: "700"
                                  }}
                                >
                                  {numToWord(item?.limit)}
                                </span>
                                &nbsp;{item?.limit > 1 ? "times" : "time"}.
                              </>
                            )}
                          </Typography>
                        </div>
                        <Typography
                          variant="header"
                          component="div"
                          sx={{
                            fontFamily: "Roboto",
                            fontSize: "3rem",
                            color: "#0B2E64",
                            fontWeight: "300"
                          }}
                        >
                          ${item?.cost}
                        </Typography>
                      </Stack>
                    ))}

                  <Stack
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ marginTop: "2.5rem" }}
                  >
                    <button
                      type="button"
                      className={"btn btn-primary signin_btn w-100"}
                      disabled={!selectedPlan?.id}
                      onClick={handleChangePlan}
                    >
                      {!activePlan && !activeAccessCode
                        ? "Proceed"
                        : "Change Plan"}
                    </button>
                  </Stack>

                  {!activePlan && !activeAccessCode && (
                    <>
                      <Divider
                        sx={{
                          margin: "2.5rem 0 1rem !important",

                          "&::before, &::after": {
                            borderTop: "1px solid #4B5563"
                          },
                          ".MuiDivider-wrapper": { margin: "0 2rem" }
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          component="span"
                          sx={{
                            color: "#4B5563",
                            textAlign: "center",
                            fontFamily: "Century Gothic",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "28px"
                          }}
                        >
                          Use an&nbsp;
                          <span style={{ color: "#0B2E64", fontWeight: "700" }}>
                            Access code
                          </span>
                          &nbsp;instead
                        </Typography>
                      </Divider>
                      <Form.Group controlId="access_code">
                        <div className={`form-floating has-validation-error`}>
                          <Form.Control
                            type="text"
                            disabled={false}
                            name="access_code"
                            placeholder="Enter access code"
                            onChange={handleAccessCodeChange}
                            className="w-100 input-placeholder"
                            isInvalid={false}
                            style={{ margin: 0 }}
                            value={accessCode}
                          />
                        </div>
                      </Form.Group>
                    </>
                  )}
                </Stack>
                {!activePlan && !activeAccessCode && (
                  <Stack
                    justifyContent="space-between"
                    spacing={2}
                    sx={{ marginTop: "2.5rem" }}
                  >
                    <button
                      type="button"
                      className={"btn btn-primary signin_btn w-100"}
                      disabled={!accessCode}
                      onClick={handleVerifyAccessCode}
                    >
                      {fromSignUp ? "Proceed" : "Proceed with Access Code"}
                    </button>
                    {fromSignUp && (
                      <button
                        className="btn btn-primary cancel_btn w-100"
                        onClick={handleSkip}
                      >
                        Skip
                      </button>
                    )}
                  </Stack>
                )}
              </Box>
            )}
          </div>
        </div>
      </div>
      <BottomNavbar />
    </>
  )
}

export default Subscriptions

import React, { useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Image } from "react-bootstrap"
import Select from "react-select"
import {
  getSurveyListing,
  getSurveyDetail,
  getSurveyDateListing
} from "../../../api/request"
import svg from "../../../assets/images/svg/index"
import Loader from "../../../CustomComponents/Loader"
import ProgressBar from "react-bootstrap/ProgressBar"
import SurveyDetail from "./SurveyDetail.jsx"
import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useGlobalFilter
} from "react-table"
import { format } from "date-fns"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./surveyListing.scss"

const SurveyListing = () => {
  const navigate = useNavigate()
  const [showAll, setShowAll] = useState(false)
  const [showSel, setShowSel] = useState(false)
  const [showDetail, setShowDetail] = useState(false)
  const [currentPageIndex, setCurrentPageIndex] = useState(1)
  const [serialNumber, setSerialNumber] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [getNextPage, setNextPage] = useState(false)
  const [getPrevPage, setPrevPage] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selDate, setSelDate] = useState(null)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [getData, setData] = useState({})
  const [loading, setLoading] = useState(true) // Add loading state
  const [noFound, setNoFound] = useState(false)
  const [products, setProducts] = useState([])
  const [getSurveyData, setSurveyData] = useState(null)

  const getSurveyDetailData = async id => {
    try {
      navigate("/survey-details/"+id)
      // const resp = await getSurveyDetail(id)
      // setSurveyData(resp)
      // console.log('getSurveyData::::',resp)
     // navigate("/survey-details",{state:{surveyData:resp}})
    } catch (err) {
      // toast.error(err?.response?.data?.message);
    }
  }

  const handleDelClose = () => {
    setShowDetail(false)
  }
  // Define the function to handle date changes
  const handleDateChange = (date = new Date()) => {
    const formattedDate = format(date, "yyyy-MM-dd")
    setSelectedDate(date)
    setSelDate(formattedDate)
    setShowDatePicker(!showDatePicker)
    fetchDateData(formattedDate, 1)
  }

  const handlePrevPage = () => {
    if (getData?.next !== null) {
      setNextPage(false)
    }
    if (getData?.previous !== null) {
      const urlParams = new URLSearchParams(new URL(getData?.previous).search)
      const pageValue = urlParams.get("page")
      if (pageValue === null) {
        setCurrentPageIndex(1)
        if (selDate !== null) {
          fetchDateData(selDate, 1)
        } else fetchData(1)
      } else {
        setCurrentPageIndex(currentPageIndex - 1)
        if (selDate !== null) {
          fetchDateData(selDate, currentPageIndex - 1)
        } else fetchData(pageValue)
      }
    } else {
      setPrevPage(true)
    }
  }

  const handleNextPage = () => {
    if (getData?.previous !== null) {
      setPrevPage(false)
    }
    if (getData?.next !== null) {
      const urlParams = new URLSearchParams(new URL(getData?.next).search)
      const pageValue = urlParams.get("page")
      setCurrentPageIndex(currentPageIndex + 1)
      if (selDate !== null) {
        fetchDateData(selDate, currentPageIndex + 1)
      } else fetchData(pageValue)
    } else {
      setNextPage(true)
    }
  }

  useEffect(() => {
    setSerialNumber((currentPageIndex - 1) * 10 + 1)
    setCurrentPageIndex(currentPageIndex)
  }, [currentPageIndex])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async pageValue => {
    setLoading(true)
    try {
      let resp = await getSurveyListing(pageValue || currentPageIndex)
      if (resp) {
        setProducts(resp?.results)
        setData(resp)
        if (resp?.next !== null) {
          setNextPage(false)
        }
        setTotalCount(resp?.count)
        setLoading(false)
        if (resp?.count === 0) {
          setNoFound(true)
          setLoading(false)
        } else {
          setNoFound(false)
        }
      }
    } catch (err) {
      setLoading(false)
      if (err?.response?.data?.detail === "Invalid token.") {
        navigate("/login")
      }
    }
  }

  const fetchDateData = async (selectedDate, pageValue) => {
    setLoading(true)
    setCurrentPageIndex(pageValue)
    try {
      let resp = await getSurveyDateListing(selectedDate || selDate, pageValue)
      if (resp) {
        setProducts(resp?.results)
        setData(resp)
        if (resp?.next !== null) {
          setNextPage(false)
        }
        setTotalCount(resp?.count)
        setLoading(false)
        if (resp?.count === 0) {
          setNoFound(true)
          setLoading(false)
        } else {
          setNoFound(false)
        }
      }
    } catch (err) {
      setLoading(false)
      if (err?.response?.data?.detail === "Invalid token.") {
        navigate("/login")
      }
    }
  }

  const data = useMemo(() => products, [products])

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No",
        accessor: "sno",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => (
          <span>{(currentPageIndex - 1) * 10 + 1 + row?.index}</span>
        )
        // Cell: ({ row }) => <span>{(row?.index + 1)}</span>,
      },
      {
        Header: "Date",
        accessor: "date",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => (
          <span>{format(new Date(row?.original?.created), "dd MMM yyyy")}</span>
        )
      },
      {
        Header: "Assessment Name",
        accessor: "name",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => <span>{row?.original?.survey_name}</span>
      },
      {
        Header: "Sections",
        accessor: "section",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => <span>{row?.original?.sections_count}</span>
      },
      {
        Header: "Questions",
        accessor: "question",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => <span>{row?.original?.questions_count}</span>
      },
      {
        Header: "Result",
        accessor: "result",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => (
          <div className="progress-val">
            <div className="progress-wrapper">
              <ProgressBar now={Number(row?.original?.percentage)} />{" "}
              <div className="progress-txt">
                {row?.original?.correct_answer}/
                {Number(row?.original?.questions_count)}
              </div>
            </div>
          </div>
        )
      },
      {
        Header: "Percentage",
        accessor: "percentage",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => (
          <span>
            {Number?.isInteger(row?.original?.percentage)
              ? Number(row?.original?.percentage)?.toString()
              : Number(row?.original?.percentage)?.toFixed(2)}
            %
          </span>
        )
      },
      {
        Header: "Action",
        accessor: "action",
        sticky: "left",
        disableSortBy: false,
        Cell: ({ row }) => <span>{row?.original?.survey_action}</span>,
        Cell: ({ row }) => (
          <Button
            className="action-btn"
            onClick={() => {
              getSurveyDetailData(row?.original?.id)
              //setShowDetail(true)
             
            }}
          >
            View
          </Button>
        )
      }
    ],
    [currentPageIndex]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  )

  const pageCount = Math.ceil(totalCount / 10)

  return (
    <>
      {loading === true ? (
        <Loader loadingMsg="Data is Loading... Please Wait" />
      ) : (
        <div className="admin-users-wrapper adminpadding">
          <div className="admin-users-heading">Assessment History</div>
          <div className="survey-divider"></div>

          <div className="survey-date-filters d-flex">
            <div
              className={
                showSel === true
                  ? "highlightedDelDate cursor-pointer"
                  : "sel-date cursor-pointer"
              }
              onClick={() => {
                setShowDatePicker(!showDatePicker)
                setShowAll(false)
                setShowSel(true)
              }}
            >
              Date
            </div>

            <div
              className={
                showAll === true
                  ? "highlightedAllDate cursor-pointer"
                  : "all-date cursor-pointer"
              }
              onClick={() => {
                setShowAll(true)
                setShowSel(false)
                setShowDatePicker(false)
                fetchData(1)
                setCurrentPageIndex(1)
                setSelDate(null)
              }}
            >
              All{" "}
              <img src={svg?.AllDate} className="all-date-img" alt="all-date" />{" "}
            </div>
          </div>

          {showDatePicker && (
            <div className="all-date-wrapper">
              <DatePicker
                selected={selectedDate}
                onSelect={handleDateChange}
                dateFormat="yyyy-MM-dd"
                inline
                className="date-picker-all-date"
              />
            </div>
          )}
          <>
            {noFound === true ? (
              <div className="no-found-txt">No assessments taken Yet!</div>
            ) : (
              <div className="admin-users-body">
                <div
                  style={{
                    backgroundColor: "##F3F4F6",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderColor: "#1334B9"
                  }}
                ></div>
                <>
                  <div className="table-container mt-4">
                    <table {...getTableProps()} className="table-cls w-100">
                      <thead className="app-users-table-head">
                        {headerGroups?.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers?.map(column => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                                <span>
                                  &nbsp;
                                  {column.disableSortBy === false ? (
                                    <Image
                                      src={svg?.Selector}
                                      className="cursor-pointer"
                                    ></Image>
                                  ) : null}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page?.map(row => {
                          prepareRow(row)
                          return (
                            <tr
                              {...row?.getRowProps()}
                              className="table-body-row"
                            >
                              {row?.cells?.map((cell, cellIndex) => {
                                return (
                                  <td {...cell?.getCellProps()}>
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="paginationBox d-flex justify-content-end mt-4">
                    <div className="pagination-body d-flex justify-content-center align-items-center">
                      <img
                        src={svg.Previcon}
                        onClick={() => {
                          if (getData?.previous !== null) handlePrevPage()
                        }}
                        disabled={getData?.previous === null}
                        className={
                          getData?.previous !== null ? "previcon" : "disabled"
                        }
                        alt="prev-icon-NP"
                      />
                      {Array.from({ length: pageCount }, (_, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            setCurrentPageIndex(index + 1)
                            if (selDate !== null) {
                              fetchDateData(selDate, index + 1)
                            } else fetchData(index + 1)
                          }}
                          className={`paginationButton ${
                            currentPageIndex === index + 1 ? "activeButton" : ""
                          }`}
                        >
                          {index + 1}
                        </button>
                      ))}
                      <img
                        src={svg.Nexticon}
                        alt="prev-icon-NP"
                        onClick={() => {
                          if (getData?.next !== null) handleNextPage()
                        }}
                        disabled={getData?.next === null}
                        className={
                          getData?.next !== null ? "previcon" : "disabled"
                        }
                      />
                    </div>
                  </div>
                </>
              </div>
            )}
          </>
        </div>
      )}
      {showDetail === true && (
        <SurveyDetail
          show={showDetail}
          handleDelClose={handleDelClose}
          surveyData={getSurveyData}
        />
      )}
    </>
  )
}

export default SurveyListing
